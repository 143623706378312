module.exports = {
	"rule_actions": {
		"types_title": "Типы действий",
		"create_success": "Действие успешно создано",
		"update_success": "Действие успешно обновлено",
		"delete_success": "Действие успешно удалено",
		"action": "Действие",
		"create": "Создать действие",
		"update": "Редактировать действие",
		"priority": "Приоритет",
		"action_type": "Тип действия",
		"add_currency": "Добавить валюту",
		"add_place": "Добавить место",
		"types": {
			"use_account": "Использовать аккаунт",
			"use_icon": "Использовать иконку",
			"mark_as_p2p": "Пометить как P2P",
			"use_processing_time_hint": "Использовать подсказку о времени обработки",
			"disable_payments": "Отключить платежи",
			"use_alternative_contacts": "Использовать альтернативные контакты",
			"set_deposit_limits": "Установить лимит депозита",
			"set_withdraw_limits": "Установить лимит выплаты",
			"use_customer_fee": "Использовать комиссию игрока",
			"set_deposit_default_amount_strategy": "Установить стратегию дефолтной суммы депозита",
			"deny_payment_with_error": "Отклонить платеж с ошибкой",
			"hide_system": "Не отображать систему",
			"set_default_deposit_amount": "Установить сумму депозита по умолчанию",
			"set_default_withdraw_amount": "Установить сумму выплаты по умолчанию",
			"set_deposit_timeout": "Установить таймаут депозитов",
			"set_method_priority": "Установить приоритет ПС",
			"make_method_preselected": "Сделать ПС предвыбранной",
			"set_amount_multiplicity": "Установить кратность суммы",
			"use_vwo_variation": "Использовать VWO вариацию",
			"set_order_description_template": "Установить темплейт описания заявки",
			"mark_problem_channels": "Пометить проблемный канал",
			"show_notification": "Показать нотификацию",
			"use_project_descriptor": "Использовать дескриптор проекта",
			"set_alt_priority": "Приоритет альта",
			"set_dashboard_comment": "Комментарий для дашборда",
			"limit_deposits_sum": "Ограничить сумму депозитов",
			"block_payments": "Заблокировать платежи",
			"set_min_rating": "Установить минимальный рейтинг ПС"
		},
		"arguments": {
			"processor_account": "Аккаунт",
			"alt_priority": "Приоритет альта",
			"icon_name": "Название иконки",
			"avg_seconds": "Ср. время в сек.",
			"max_seconds": "Макс. время в сек.",
			"from_amount": "От",
			"to_amount": "До",
			"customer_fee_fix_amount": "Фиксированная сумма",
			"customer_fee_percentage": "Процент",
			"customer_fee_percentage_exceeding": "Процент для суммы свыше",
			"customer_fee_max_amount": "Максимальная сумма комиссии",
			"customer_fee_show": "Показывать комиссию в виджете",
			"deposit_default_amount_strategy": "Стратегия дефолтной суммы депозита",
			"error_code": "Код ошибки",
			"amount": "Сумма",
			"system_priority": "Приоритет ПС",
			"system_priority_hint": "Чем больше число, тем выше приоритет. По умолчанию 999",
			"multiplicity_amount": "Величина кратности",
			"vwo_experiment": "Эксперимент",
			"vwo_variation": "Вариация",
			"order_description_template": "Темплейт описания заявки",
			"order_description_template_hint": "Поддерживаемые плейсхолдеры: {{{project_name}}}, {{{external_id}}}, {{{payment_type}}}",
			"currency_limit_mapping": "Лимиты для валют",
			"currency_limit_mapping_hint": "Берутся лимиты для валюты. Если валюты нет, то берутся первые в списке.",
			"chronic_duration": "Продолжительность",
			"project_descriptor": "Дескриптор проекта",
			"notification": "Код",
			"notification_screen": "Экран",
			"notification_system": "ПС (опционально)",
			"comment": "Комментарий",
			"processor_names": "Процессинги",
			"system_names": "Системы",
			"period": "Период",
			"reason": "Причина",
			"system_rating": "Рейтинг системы"
		},
		"list": {
			"customer_fee": {
				"exceeding": "для суммы свыше",
				"max_amount": "максимальная комиссия",
				"show": "показывать в виджете",
				"dont_show": "не показывать в виджете"
			},
			"deposit_default_amount_strategy": {
				"last_deposit": "последний депозит",
				"average": "среднее",
				"rule_or_rating": "правило или рейтинг",
				"max_of_last_deposit_and_average": "максимальное из последнего и среднего депозита"
			},
			"error_code": "Error code",
			"modifiers": {
				"payment_period": "значением {subject}, период — {period}",
				"payment_period_processors": "значением {subject} через процессинги {processors}, период — {period}",
				"payment_period_systems": "значением {subject} через системы {systems}, период — {period}",
				"payment_all": "значением {subject} через процессинги {processors} и системы {systems}, период — {period}"
			}
		},
		"validation": {
			"code_screen_required": "Код и экран обязательны для заполнения"
		}
	}
};